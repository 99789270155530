import React from 'react';
import { menuData } from '../../data/MenuData';
import { ButtonCSS } from '../Button';
import {
  BtnWrap,
  CloseIcon,
  DropdownContainer,
  DropdownLink,
  DropdownMenu,
  DropdownWrapper,
  Icon,
  PromoLink,
  BarKLink,
} from './DropDownElements';

const DropDown = ({ isOpen, toggle }) => {
  return (
    <DropdownContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <DropdownWrapper>
        <DropdownMenu>
          <DropdownLink
            to='About'
            smooth={true}
            duration={500}
            spy={true}
            exact='true'
            offset={-50}
            onClick={toggle}
          >
            About
          </DropdownLink>
          <DropdownLink
            to='Room'
            smooth={true}
            duration={500}
            spy={true}
            exact='true'
            offset={-50}
            onClick={toggle}
          >
            Room & Amenities
          </DropdownLink>
          <BarKLink
            href='https://tinianbarkdiner.com'
            target='_blank'
            rel='noopener'
          >
            Bar-K Diner
          </BarKLink>
          <PromoLink to='/promo'>Promo</PromoLink>
          <DropdownLink
            to='Contact'
            smooth={true}
            duration={500}
            spy={true}
            exact='true'
            offset={-50}
            onClick={toggle}
          >
            Contact Us
          </DropdownLink>
          <DropdownLink
            to='Room'
            smooth={true}
            duration={500}
            spy={true}
            exact='true'
            offset={-30}
            onClick={toggle}
          >
            Book Now
          </DropdownLink>
          {/* {menuData.map((item, index) => {
            return (
              <DropdownLink
                to={item.link}
                key={index}
                smooth={true}
                duration={500}
                spy={true}
                exact='true'
                offset={-80}
                onClick={toggle}
              >
                {item.title}
              </DropdownLink>
            );
          })} */}
        </DropdownMenu>
      </DropdownWrapper>
    </DropdownContainer>
  );
};

export default DropDown;
