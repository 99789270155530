import styled from 'styled-components';
import { Link as LinkS } from 'react-scroll';

export const Section = styled.section`
  width: 100%;
  height: 100%;
  padding: 4rem 0rem;
`;

export const SectionContainer = styled.div`
  padding: 1rem calc((100vw - 1300px) / 2);
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 50px;
  /* grid-template-rows: 600px; */

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const ColumnLeft = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: flex-start;
  line-height: 1.4;
  padding: 1rem 2rem;
  order: ${({ reverse }) => (reverse ? '2' : '1')};

  h1 {
    margin-bottom: 1rem;
    font-size: clamp(1.5rem, 6vw, 2rem);
    font-weight: 400;
  }

  p {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    /* padding: 0 2rem; */
    text-align: justify;
  }
`;

export const ColumnRight = styled.div`
  padding: 1rem 2rem;
  order: ${({ reverse }) => (reverse ? '1' : '2')};
  display: flex;
  /* justify-content: center; */
  align-items: flex-start;

  @media screen and (max-width: 768px) {
    order: ${({ reverse }) => (reverse ? '2' : '1')};
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;

    @media screen and (max-width: 768px) {
      width: 90%;
      height: 90%;
    }
  }
`;

export const Line = styled.div`
  width: 65px;
  border-top: 5px solid #f3a71d;
  border-width: 5px;
  padding: 0.25rem 0;
`;

export const BookLink = styled(LinkS)`
  background: ${({ primary }) => (primary ? '#CD853F' : '#000D1A')};
  white-space: nowrap;
  outline: none;
  border: none;
  min-width: 100px;
  max-width: 200px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ big }) => (big ? '16px 40px' : '14px 24px')};
  color: ${({ primary }) => (primary ? '#fff' : '#000D1A')};
  font-size: ${({ big }) => (big ? '20px' : '14px')};
  border-radius: ${({ round }) => (round ? '25px' : '5px')};

  &:hover {
    transform: translateY(-2px);
    background: ${({ primary }) => (primary ? '#CD853F' : '#000D1A')};
    color: ${({ primary }) => (primary ? '#000' : '#fff')};
  }
`;
