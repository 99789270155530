import Image4 from '../images/Image4.jpg';

export const InfoDataOne = {
  heading: 'Hotel Overview',
  paragraphOne:
    'Experience paradise while you enjoy the amenities and comforts of your hotel room. If you are looking for additional information or need any other services, please contact front desk team.',
  paragraphTwo:
    'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Incidunt sunt cupiditate culpa tempore excepturi debitis sit tenetur tempora ad quaerat!',
  buttonLabel: 'Book Now',
  image: Image4,
  reverse: false,
  delay: 100,
  ScrollId: 'About',
};
