import React, { useState, useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import validator from 'validator';
import Axios from 'axios';
import {
  BtnWrapper,
  ContactsInfo,
  FooterContainer,
  FooterLinkItems,
  FooterLinksWrapper,
  FooterWrap,
  FormContainer,
  FormHolder,
  FormWrap,
  Line,
  MailContainer,
  MailLogo,
  PhoneLogo,
  InputWrapper,
  CopyRightContainer,
  CopyRightWrapper,
  WebsiteRights,
  FormInput,
  FormInputMessage,
  Button,
} from './FooterElements';
gsap.registerPlugin(ScrollTrigger);

// import LogoS from '../../images/logo_s.png';

// import { ButtonCSS } from '../Button';

const Footer = () => {
  const [guestName, setGuestName] = useState('');
  const [guestEmail, setGuestEmail] = useState('');
  const [guestContact, setGuestContact] = useState('');
  const [guestMessage, setGuestMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  let contactRef = useRef();

  useEffect(() => {
    gsap.from('.animate-contact', {
      duration: 0.5,
      autoAlpha: 0,
      ease: 'none',
      x: -100,
      scrollTrigger: {
        trigger: '#Contact',
        // endTrigger: '#Room',
        start: 'top center',
        end: 'bottom +=50%',
        toggleActions: 'play none none reverse',
        // markers: true,
      },
    });

    gsap.from('.animate-msg', {
      duration: 0.5,
      autoAlpha: 0,
      ease: 'none',
      x: 200,
      scrollTrigger: {
        trigger: '#Contact',
        // endTrigger: '#Room',
        start: 'top center',
        end: 'bottom +=50%',
        toggleActions: 'play none none reverse',
        // markers: true,
      },
    });
  }, []);

  const setInitSate = () => {
    setGuestName('');
    setGuestEmail('');
    setGuestContact('');
    setGuestMessage('');
    setIsProcessing(false);
  };

  const handleChangeName = (ev) => {
    setGuestName(ev.target.value);
  };

  const handleChangeEmail = (ev) => {
    setGuestEmail(ev.target.value);
  };

  const handleChangeContact = (ev) => {
    setGuestContact(ev.target.value);
  };

  const handleChangeMessage = (ev) => {
    setGuestMessage(ev.target.value);
  };

  const validation = () => {
    return new Promise((resolve, reject) => {
      if (validator.isEmpty(guestName)) {
        alert('Please enter your name!');
        resolve(false);
      }

      if (!validator.isEmail(guestEmail)) {
        alert('Please enter email!');
        resolve(false);
      }

      if (validator.isEmpty(guestContact)) {
        alert('Please enter contact number');
        resolve(false);
      }

      if (validator.isEmpty(guestMessage)) {
        alert('Please enter your message');
        resolve(false);
      }

      resolve(true);
    });
  };

  const handleSendMsg = async (ev) => {
    try {
      ev.preventDefault();
      let isValid = await validation();

      if (!isValid) {
        return false;
      } else {
        setIsProcessing(true);
        processEmail()
          .then(() => {
            alert('Your message has been forwarded to our front desk.');
            setInitSate();
          })
          .catch(() => {
            alert(
              'We appologize but this service is temporarily not available.'
            );
            setInitSate();
          });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const processEmail = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let bodyData = {
          CustomerMessage: guestMessage,
          GuestName: guestName,
          GEmail: guestEmail,
          Contact: guestContact,
        };

        let response = await Axios.post(`/api/email/sendmessage`, bodyData);

        if (response.data === 'Success') {
          resolve('Success');
        } else {
          reject('Failed');
        }
      } catch (e) {
        console.log(e);
        reject('Failed');
      }
    });
  };

  return (
    <FooterContainer id='Contact'>
      <FooterWrap
        ref={(element) => {
          contactRef = element;
        }}
      >
        <FooterLinksWrapper>
          <FooterLinkItems className='animate-contact'>
            <ContactsInfo>
              <h1>Contact Us</h1>
              <Line />
              <p>P.O.Box 520397, Tinian, MP 96952</p>
              <p>Northen Mariana Islands</p>
              <MailContainer>
                <MailLogo />
                <h4>reservation@tinianwesternlodge.com</h4>
              </MailContainer>
              <MailContainer>
                <PhoneLogo />
                <h5>(670)433-0044</h5>
              </MailContainer>
            </ContactsInfo>
          </FooterLinkItems>
        </FooterLinksWrapper>
        <FooterLinksWrapper>
          <FooterLinkItems>
            <div className='animate-msg'>
              <h1>Leave A Message</h1>
              <Line />
              <FormContainer>
                <FormHolder
                  className=''
                  noValidate
                  autoComplete='off'
                  action='/'
                  method='POST'
                >
                  <FormWrap>
                    <InputWrapper>
                      <FormInput
                        type='text'
                        placeholder='Your Full Name'
                        onChange={handleChangeName}
                        value={guestName}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <FormInput
                        type='email'
                        placeholder='Your Email Address'
                        onChange={handleChangeEmail}
                        value={guestEmail}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <FormInput
                        type='text'
                        placeholder='Your Contact'
                        onChange={handleChangeContact}
                        value={guestContact}
                      />
                    </InputWrapper>
                    <FormInputMessage
                      type='text'
                      rows='5'
                      placeholder='Your Message...'
                      onChange={handleChangeMessage}
                      value={guestMessage}
                    />
                    <BtnWrapper>
                      <Button disabled={isProcessing} onClick={handleSendMsg}>
                        {isProcessing
                          ? 'Sending Message...'
                          : 'Send Booking Request'}
                      </Button>
                    </BtnWrapper>
                  </FormWrap>
                </FormHolder>
              </FormContainer>
            </div>
          </FooterLinkItems>
        </FooterLinksWrapper>
      </FooterWrap>
      <CopyRightContainer>
        <CopyRightWrapper>
          <WebsiteRights>
            Triple J © {new Date().getFullYear()} All rights reserved.
          </WebsiteRights>
        </CopyRightWrapper>
      </CopyRightContainer>
    </FooterContainer>
  );
};

export default Footer;
