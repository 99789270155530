import { AiOutlineCar, AiFillCar, AiOutlineWifi } from 'react-icons/ai';
import { BsCameraVideo } from 'react-icons/bs';
import { GrLounge } from 'react-icons/gr';
import { CgSmartHomeWashMachine } from 'react-icons/cg';
import { BiFoodMenu } from 'react-icons/bi';

export const AmenitiesData = [
  { label: 'Hotel Guest Parking', iCon: AiOutlineCar },
  { label: 'Guest Self-laundry Service', iCon: CgSmartHomeWashMachine },
  { label: 'Full-service Rooms with Amenities', iCon: BiFoodMenu },
  { label: 'Hertz Rental Car', iCon: AiFillCar },
  { label: 'Lobby Lounge', iCon: GrLounge },
  { label: 'Free WiFi', iCon: GrLounge },
  { label: `Smart TV's in every room`, iCon: GrLounge },
  {
    label:
      'Onsite Bar-K Diner restaurant serving breakfast, lunch and dinner options',
    iCon: GrLounge,
  },
];
