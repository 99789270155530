import styled, { css } from 'styled-components/macro';
import { IoArrowBack, IoArrowForward } from 'react-icons/io5';

export const HeroSection = styled.section`
  height: 70vh;
  max-height: 1100px;
  position: relative;
  overflow: hidden;
`;

export const HeroWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
`;

export const HeroSlide = styled.div`
  z-index: 1;
  width: 100%;
  height: 100%;
`;

export const HeroSlider = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: '';
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100vh;
    bottom: 0vh;
    left: 0;
    overflow: hidden;
    opacity: 0.4;
    transition: opacity 0.3s ease-in-out;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 50%,
      rgba(0, 0, 0, 0.6) 100%
    );
  }
`;

export const HeroImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  object-fit: cover;
`;

const arrowButtons = css`
  width: 50px;
  height: 50px;
  color: #fff;
  cursor: pointer;
  background: #000d1a;
  border-radius: 50px;
  padding: 10px;
  margin-right: 1rem;
  user-select: none;
  transition: 0.3s;

  &:hover {
    background: #cd853f;
    transform: scale(1.05);
  }
`;

export const PrevArrow = styled(IoArrowBack)`
  ${arrowButtons}
`;

export const NextArrow = styled(IoArrowForward)`
  ${arrowButtons}
`;

export const SliderButtons = styled.div`
  position: absolute;
  bottom: 30px;
  right: 30px;
  display: flex;
  z-index: 10;

  @media screen and (max-width: 450px) {
    bottom: 20px;
    right: 20px;
  }
`;

export const TitleContainer = styled.div`
  position: absolute;
  z-index: 10;
  text-align: center;
  color: #fff;
  width: 90%;
  margin-top: 150px;
  @media screen and (max-width: 450px) {
    margin-top: 60px;
  }
`;

export const HeroTitle = styled.h3`
  font-size: clamp(1rem, 6vw, 3rem);
  font-weight: 700;
  text-shadow: 2px 0px 5px rgba(0, 0, 0, 0.7);

  @media screen and (max-width: 450px) {
    font-weight: 600;
  }
`;

export const HeroSubtitle = styled.p`
  font-size: clamp(0.25rem, 6vw, 1.2rem);
  font-weight: lighter;
  text-shadow: 2px 0px 5px rgba(0, 0, 0, 0.9);
  margin-top: 20px;
  line-height: 1.7rem;
`;
