import styled from 'styled-components';
import { MdClose } from 'react-icons/md';
import { Link } from 'react-router-dom';

export const BackGround = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

export const ModalWrapper = styled.div`
  width: 600px;
  height: 550px;
  box-shadow: 0.5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  border: 1px solid gray;
  color: #000;
  display: grid;
  grid-template-columns: 1fr;
  position: relative;
  z-index: 100;
  border-radius: 10px;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 40px;
`;
export const FormInput_1 = styled.input`
  padding: 1rem;
  outline: none;
  width: 100%;
  height: 10px;
  border: none;
  margin: 5px 0;
  border-radius: 4px;
  border: 1px solid gray;
`;

export const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 20;
`;

export const Label = styled.span`
  font-size: clamp(0.5rem, 8vw, 0.9rem);
  font-weight: 600;
  margin: 5px 0;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  /* align-items: flex-end; */
`;

export const Button = styled.button`
  background: ${({ disabled }) =>
    disabled ? '#000d1a' : '#cd853f'}; //#cd853f;
  white-space: nowrap;
  outline: none;
  border: none;
  min-width: 100px;
  max-width: 200px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 24px;
  color: #fff;
  font-size: 14px;
  border-radius: 10px;

  &:hover {
    background: #000d1a;
    color: #fff;
  }
`;

export const FormContainer = styled.form`
  font-size: 14px;
  box-sizing: border-box;
  line-height: 1;
  width: 100%;
`;

export const FormTitle = styled.div`
  font-size: 1.5em;
  font-weight: 600;
  margin-bottom: 10px;
  position: relative;
`;

export const Star = styled.label`
  font-size: 0.75em;
  position: absolute;
`;

export const FormItem = styled.div`
  /* display: flex;
  flex-direction: columns;
  align-self: flex-start; */
  display: grid;
  grid-template-columns: 1fr 3fr;
  justify-content: center;
  text-align: left;
  width: 100%;
`;

export const FormLabel = styled.label`
  font-weight: 600;
  padding: 10px 0;
`;

export const FormInput = styled.input`
  -webkit-appearance: none;
  width: 100%;
  /* max-width: 425px; */

  /* Fix for Safari:iOs */
  min-height: calc(0.9em + (0.8em * 2) + 0.6em);
  padding: 0.8em;
  font-size: 0.9em;

  color: ${({ hasError }) => (hasError ? '#d50000' : '#00000')};
  outline: none;
  border: 1px solid #dddd;
  border-radius: 4px;
  background: ${({ hasError }) => (hasError ? '#fff8f8' : '#f9f9f9')};
  border-color: ${({ hasError }) => (hasError ? '#d50000' : '#00000')};
  transition: background 0.25s, border-color 0.25s, color 0.25s;

  &:focus {
    background: #ffff;
  }

  &::placeholder {
    color: ${({ hasError }) => (hasError ? '#ffbfbf' : '#bbbb')};
  }
`;

export const FormError = styled.span`
  padding-top: 10px;
  color: #d50000;
  font-size: 0.9em;
  visibility: ${({ hasError }) => (hasError ? '' : 'hidden')};
`;

export const RadioLabel = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
  padding: 10px 0;
  font-size: 1em;
  font-weight: 600;
`;

export const RadioInput = styled.input`
  display: none;
`;

export const RadioContainer = styled.div`
  width: 1.25em;
  height: 1.25em;
  border: 2px solid #d8e4e2;
  border-radius: 50%;
  margin-right: 10px;
  box-sizing: border-box;
  padding: 2px;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    background: #cd853f;
    border-radius: 50%;
    transition: transform 0.15s;
    transform: ${({ optRoomOnly }) =>
      optRoomOnly ? 'scale(1)' : 'scale(0)'}; //scale(0);
  }
`;

export const ButtonQuoteContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 0.7rem;
`;

export const TextQuote = styled.label`
  align-items: center;
  justify-items: center;
  font-size: 0.7rem;
  font-style: italic;
  font-weight: bold;
  color: #ac0505;
  padding-right: 1rem;
`;
