import React, { useState, useRef, useEffect } from 'react';

import {
  HeroImage,
  HeroSection,
  HeroSlide,
  HeroSlider,
  HeroSubtitle,
  HeroTitle,
  HeroWrapper,
  NextArrow,
  PrevArrow,
  SliderButtons,
  TitleContainer,
} from './HeroElements';

const Hero = ({ slides }) => {
  const [current, setCurrent] = useState(0);
  const length = slides.length;
  const timeout = useRef(null);

  useEffect(() => {
    const nextSlide = () => {
      setCurrent((current) => (current === length - 1 ? 0 : current + 1));
    };

    timeout.current = setTimeout(nextSlide, 50000);

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [current, length]);

  const nextSlide = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }

  return (
    <HeroSection>
      <HeroWrapper>
        {slides.map((slide, index) => {
          return (
            <HeroSlide key={index}>
              {index === current && (
                <HeroSlider>
                  <HeroImage src={slide.image} alt={slides.alt} />
                </HeroSlider>
              )}
            </HeroSlide>
          );
        })}
        <TitleContainer>
          <HeroTitle>Welcome to the Tinian Western Lodge</HeroTitle>
          <HeroSubtitle>
            We are located on Broadway Avenue – the island’s thoroughfare into
            the San Jose Village. Centrally located whether you are on island
            for business or leisure, our staff look forward to making your stay
            on Tinian enjoyable. Our rooms are newly renovated, well lit,
            air-conditioned, and inviting. It is our mission to deliver
            unmatched service ensuring you enjoy your experience with us.
          </HeroSubtitle>
        </TitleContainer>
        <SliderButtons>
          <PrevArrow onClick={prevSlide} />
          <NextArrow onClick={nextSlide} />
        </SliderButtons>
      </HeroWrapper>
    </HeroSection>
  );
};

export default Hero;
