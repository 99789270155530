import React, { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import { PromoContainer } from './PromoElements';
const Promo = ({ PdfFile }) => {
  // console.log(typeof PdfFile);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    console.log(numPages);
  };

  return (
    <PromoContainer>
      <Document file={PdfFile} onLoadSuccess={onDocumentLoadSuccess}>
        {/* <Page pageNumber={pageNumber} scale={2} /> */}
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
      </Document>
    </PromoContainer>
  );
};

export default Promo;
