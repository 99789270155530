import React from 'react';
import { ButtonCSS } from '../Button';
import {
  DateContainer,
  DateWrapper,
  SelectContainer,
} from './BookDatesElement';
import DatePicker from 'react-date-picker';
import Select from 'react-select';
import momentTZ from 'moment-timezone';

const options = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
];

const BookDates = ({
  ToggleModal,
  checkInDate,
  checkOutDate,
  setCheckInDate,
  setCheckOutDate,
  roomQty,
  setRoomQty,
}) => {
  // const [ArrivalDate, setArrivalDate] = useState(new Date());
  // const [DepartDate, setDepartDate] = useState(new Date());
  // const [roomQty, setRoomQty] = useState(1);

  const modifiedDate = (dateValue) => {
    let AdjDate = new Date(momentTZ.tz(dateValue, 'Pacific/Guam'));
    return AdjDate;
  };

  const CheckInDateChange = (dateValue) => {
    setCheckInDate(modifiedDate(dateValue));
    // let today = new Date();
    let tomorrow = modifiedDate(dateValue);
    // console.log({ dateValue });
    tomorrow.setDate(dateValue.getDate() + 1);
    // console.log(tomorrow.toLocaleDateString());
    CheckOutDateChange(tomorrow);
  };

  const CheckOutDateChange = (date) => {
    setCheckOutDate(modifiedDate(date));
  };

  const handleChangeRoom = (selectedOption) => {
    // console.log(selectedOption.value);
    setRoomQty(selectedOption.value);
  };

  const handleMailTo = () => {
    let email = 'reservation@tinianwesternlodge.com';
    let subject = 'Requesting for reservation';
    let emailBody = '';
    document.location = `mailto:${email}?subject=${subject}&body=${emailBody}`;
  };

  return (
    <DateContainer>
      <DateWrapper>
        <SelectContainer>
          <h2>Check In</h2>
          <DatePicker
            className='react-date-picker__box'
            onChange={CheckInDateChange}
            isOpen={false}
            minDate={new Date()}
            value={checkInDate}
            clearIcon={false}
            format='MM/dd/y'
          />
        </SelectContainer>
        <SelectContainer>
          <h2>Check Out</h2>
          <DatePicker
            className='react-date-picker__box'
            onChange={CheckOutDateChange}
            isOpen={false}
            minDate={checkInDate}
            value={checkOutDate}
            clearIcon={false}
            format='MM/dd/y'
          />
        </SelectContainer>
        <SelectContainer>
          <h2>Rooms</h2>
          <Select
            className='select_room'
            classNamePrefix='select_room__text__single-value select_room__text__menu'
            defaultValue={options[0]}
            onChange={handleChangeRoom}
            options={options}
            value={options[roomQty - 1]}
            singleValue
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
          />
        </SelectContainer>
        <ButtonCSS to='/' big='true' primary='true' onClick={ToggleModal}>
          Book Now
        </ButtonCSS>
      </DateWrapper>
    </DateContainer>
  );
};

export default BookDates;
