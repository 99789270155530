import React, { useState } from 'react';
import Hero from '../components/Hero';
import InfoSection from '../components/InfoSection';
import Room from '../components/Room';
import MyGoogleMap from '../components/GoogleMap';
import { InfoDataOne } from '../data/InfoData';
import { SliderData } from '../data/SliderData';

const Home = ({
  ToggleModal,
  checkInDate,
  checkOutDate,
  setCheckInDate,
  setCheckOutDate,
  roomQty,
  setRoomQty,
}) => {
  return (
    <>
      <Hero slides={SliderData} />
      <InfoSection {...InfoDataOne} ToggleModal={ToggleModal} />
      <Room
        ToggleModal={ToggleModal}
        checkInDate={checkInDate}
        checkOutDate={checkOutDate}
        setCheckInDate={setCheckInDate}
        setCheckOutDate={setCheckOutDate}
        roomQty={roomQty}
        setRoomQty={setRoomQty}
      />
      <MyGoogleMap />
    </>
  );
};

export default Home;
