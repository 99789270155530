import styled from 'styled-components';

export const DateContainer = styled.div`
  display: flex;
  position: absolute;
  /* z-index: 15; */
  bottom: 0;
  transform: translateY(40%);
  width: 100%;
  height: 100px;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    position: relative;
    transform: 0;
    transform: translateY(0%);
    border-bottom: 2px solid gray;
    height: 100%;
  }
`;

export const DateWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  height: 100%;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  /* background-color: linear-gradient(red, yellow); //#faf9f9; */
  background: rgb(215, 215, 215);
  background: linear-gradient(
    0deg,
    rgba(215, 215, 215, 1) 0%,
    rgba(218, 218, 218, 1) 18%,
    rgba(255, 255, 255, 1) 100%
  );
  max-width: 95vw;
  z-index: 50;
  border-radius: 10px;

  @media screen and (max-width: 768px) {
    border-radius: 0;
    max-width: 100vw;
    grid-template-columns: 1fr 1fr;
    padding: 0 2rem;
  }

  @media screen and (max-width: 450px) {
    border-radius: 0;
    max-width: 100vw;
    grid-template-columns: 1fr;
    padding: 0 2rem 1rem 2rem;
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  h2 {
    margin-right: 1rem;
    font-size: clamp(0.5rem, 5vw, 1.5rem);
  }

  @media screen and (max-width: 768px) {
    margin: 1rem 0;
    justify-content: left;
    h2 {
      margin-right: 0;
      width: 130px;
      font-weight: 400;
    }
  }
`;
