import React, { useState, useEffect } from 'react';
// import { menuData } from '../../data/MenuData';
import { animateScroll as scroll } from 'react-scroll';
import { useHistory } from 'react-router-dom';
import {
  Logo,
  MenuBars,
  Nav,
  NavMenu,
  NavMenuLinks,
  NavBtnWrapper,
  ImgLogo,
  NavbarContainer,
  MenuTitle,
  BookLink,
  BarKLink,
  PromoLink,
} from './NavbarElements';
import { ButtonCSS } from '../Button';
import LogoS from '../../images/logo_s.png';
// import LogoW from '../../images/logo_white.png';

const Navbar = ({ toggle, setShowModal }) => {
  const [ScrollNav, setScrollNav] = useState(false);
  const history = useHistory();

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
    return () => {
      // cleanup
    };
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  // const handleMailTo = () => {
  //   let email = 'reservation@tinianwesternlodge.com';
  //   let subject = 'Requesting for reservation';
  //   let emailBody = '';
  //   document.location = `mailto:${email}?subject=${subject}&body=${emailBody}`;
  // };

  const toggleShowModal = () => {
    setShowModal((prev) => !prev);
  };

  const scrollToElement = () => {
    history.push('/');
  };
  return (
    <Nav ScrollNav={ScrollNav}>
      <NavbarContainer>
        <Logo to='/' onClick={toggleHome}>
          <ImgLogo src={LogoS} alt='Logo' />
        </Logo>
        <MenuBars onClick={toggle} ScrollNav={ScrollNav} />
        <NavMenu>
          <NavMenuLinks
            ScrollNav={ScrollNav}
            to='About'
            smooth={true}
            duration={500}
            spy={true}
            exact='true'
            offset={-50}
            onClick={scrollToElement}
          >
            About
          </NavMenuLinks>
          <NavMenuLinks
            ScrollNav={ScrollNav}
            to='Room'
            smooth={true}
            duration={500}
            spy={true}
            exact='true'
            offset={-50}
            onClick={scrollToElement}
          >
            Room & Amenities
          </NavMenuLinks>
          <BarKLink
            href='https://tinianbarkdiner.com'
            target='_blank'
            rel='noopener'
          >
            Bar-K Diner
          </BarKLink>

          <PromoLink to='/promo'>Promo</PromoLink>

          <NavMenuLinks
            ScrollNav={ScrollNav}
            to='Contact'
            smooth={true}
            duration={500}
            spy={true}
            exact='true'
            offset={-80}
          >
            Contact Us
          </NavMenuLinks>
          <NavBtnWrapper>
            <BookLink
              to='Room'
              smooth={true}
              duration={500}
              spy={true}
              exact='true'
              offset={-50}
              round='true'
              primary='false'
              onClick={toggleShowModal}
            >
              Book Now
            </BookLink>
          </NavBtnWrapper>
        </NavMenu>
      </NavbarContainer>
    </Nav>
  );
};

export default Navbar;
