import styled from 'styled-components';
import { GoMail } from 'react-icons/go';
import { FiPhoneCall } from 'react-icons/fi';
import { FaFax, FaInstagram, FaFacebookF } from 'react-icons/fa';
import { SiTripadvisor } from 'react-icons/si';

export const FooterContainer = styled.footer`
  background-color: #f9f9f9;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const FooterWrap = styled.div`
  padding: 48px 24px;
  display: flex;

  /* flex-direction: row; */
  max-width: 95vw;
  margin: 0 auto;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    max-width: 100vw;
    width: 100%;
  }
`;

export const FooterLinksWrapper = styled.div`
  display: flex;
  width: calc(100% / 2);
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px;
  text-align: left;
  width: 100%;
  color: #000;

  h1 {
    font-size: clamp(1.5rem, 6vw, 0.5rem);
    font-weight: 500;
    margin-bottom: 1rem;
  }

  h3 {
    font-size: clamp(1rem, 6vw, 0.5rem);
    font-weight: 700;
    margin-top: 1.5rem;
  }

  p {
    line-height: 2;
    font-size: clamp(1rem, 6vw, 0.3rem);
    text-align: justify;
  }
`;

export const ContactsInfo = styled.div`
  /* width: 350px; */
  padding-left: 2.5rem;

  @media screen and (max-width: 768px) {
    width: 100%;
    padding-left: 0;
  }

  h1 {
    font-size: clamp(1.5rem, 6vw, 0.5rem);
    font-weight: 500;
    margin-bottom: 1rem;
  }

  h3 {
    font-size: clamp(1rem, 6vw, 0.5rem);
    font-weight: 700;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }

  p {
    line-height: 2;
    font-size: clamp(1rem, 6vw, 0.3rem);
  }
`;

export const Line = styled.div`
  width: 65px;
  border-top: 5px solid #f3a71d;
  border-width: 5px;
  padding: 0.25rem 0;
`;

export const ImgContainer = styled.div`
  height: 100px;
  width: 100px;
  margin-bottom: 2rem;
`;

export const ImgLogo = styled.img`
  object-fit: contain;
  height: 100%;
`;

export const MailLogo = styled(GoMail)`
  margin: auto 0;
  color: #000d1a;
`;
export const PhoneLogo = styled(FiPhoneCall)`
  margin: auto 0;
  color: #000d1a;
`;

export const FaxLogo = styled(FaFax)`
  margin: auto 0;
  color: #000d1a;
`;

export const MailContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: flex-start;

  h4 {
    font-size: 1rem;
    font-weight: 400;
    margin-left: 0.5rem;
    color: #000d1a;
    padding: 0.5rem 0;
  }

  h5 {
    margin-left: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
    padding: 0.5rem 0;
  }
`;

export const FormContainer = styled.div`
  display: flex;

  /* width: 500px; */

  /* @media screen and (max-width: 1100px) {
    width: 300px;
  } */

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const FormWrap = styled.div`
  width: 100%;
  height: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
`;

export const InputWrapper = styled.div`
  margin-bottom: 1rem;
  width: 100%;
`;

export const FormInput = styled.input`
  padding: 1rem 1.5rem;
  outline: none;
  width: 90%;
  height: 50px;
  border: none;
  margin-right: 1rem;
  border-radius: 4px;
  border: 1px solid gray;
`;

export const FormInputMessage = styled.textarea`
  padding: 1rem 1.5rem;
  outline: none;
  width: 90%;
  height: 120px;
  border: none;
  margin-right: 1rem;
  border-radius: 4px;
  border: 1px solid gray;
`;

export const FormHolder = styled.form`
  width: 100%;
`;

export const BtnWrapper = styled.div`
  width: 100%;
  margin-top: 1rem;
`;

export const SocialMediaContainer = styled.div`
  width: 100%;
  display: flex;
`;

export const SocialMediaWrapper = styled.div`
  display: flex;
  width: 100%;

  align-items: center;
  flex-direction: row;
  margin-top: 1rem;
`;

export const IconIG = styled(FaInstagram)`
  font-size: 1rem;
  margin-right: 1rem;
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid #000;
  width: 40px;
  height: 40px;
  padding: 0.3rem;
  &:hover {
    color: #fff;
    background: #f3a71d;
    border-color: #f3a71d;
  }
`;

export const IconFB = styled(FaFacebookF)`
  font-size: 0.8rem;
  margin-right: 1rem;
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid #000;
  width: 40px;
  height: 40px;
  padding: 0.4rem;
  &:hover {
    color: #fff;
    background: #f3a71d;
    border-color: #f3a71d;
  }
`;

export const IconTA = styled(SiTripadvisor)`
  font-size: 0.8rem;
  margin-right: 1rem;
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid #000;
  width: 40px;
  height: 40px;
  padding: 0.4rem;
  &:hover {
    color: #fff;
    background: #f3a71d;
    border-color: #f3a71d;
  }
`;

export const CopyRightContainer = styled.div`
  max-width: 100vw;
  width: 100%;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #e5e5e5;
`;

export const CopyRightWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const WebsiteRights = styled.small`
  color: #000d1a;
`;

export const Button = styled.button`
  background: ${({ disabled }) =>
    disabled ? '#000d1a' : '#cd853f'}; //#cd853f;
  white-space: nowrap;
  outline: none;
  border: none;
  min-width: 100px;
  max-width: 200px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 24px;
  color: #fff;
  font-size: 14px;
  border-radius: 10px;
  margin-top: 25px;

  &:hover {
    background: #000d1a;
    color: #fff;
  }
`;
