// import Image1 from '../images/Image1.jpg';
import Image2 from '../images/Image2.jpg';
import Image9 from '../images/Image9.jpg';
import Image4 from '../images/Image4.jpg';
import Image6 from '../images/Image6.jpg';
import Image7 from '../images/Image7.jpg';
import Image8 from '../images/Image8.jpg';
// import Image10 from '../images/Image10.jpg';

export const SliderData = [
  {
    image: Image7,
    alt: 'Room2',
  },
  {
    image: Image2,
    alt: 'Door',
  },
  {
    image: Image9,
    alt: 'BarKDiner',
  },
  {
    image: Image4,
    alt: 'Lounge',
  },
  {
    image: Image6,
    alt: 'Room1',
  },
  {
    image: Image8,
    alt: 'Room3',
  },
];
