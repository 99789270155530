import styled, { css } from 'styled-components/macro';
import { Link as LinkR } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';
import { FaBars } from 'react-icons/fa';

export const Nav = styled.nav`
  background: ${({ ScrollNav }) =>
    ScrollNav ? '#ffff' : 'rgba(255,255,255,0.7)'};
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  z-index: 50;
  position: fixed;
  top: 0;
  width: 100%;
`;

export const NavItem = styled.li`
  text-decoration: none;
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  width: 100%;
`;

export const Logo = styled(LinkR)`
  display: flex;
`;

export const ImgLogo = styled.img`
  display: flex;
  height: 90px;
  object-fit: fill;
`;

export const MenuBars = styled(FaBars)`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    height: 40px;
    width: 40px;
    background-size: contain;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-50%, 50%);
    color: '#cd853f';
  }
`;

export const NavMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* margin-right: 5px; */
  /* margin-left: 200px; */

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavLinksCss = css`
  display: flex;
  align-items: center;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  padding: 1rem auto;
`;

export const NavMenuLinks = styled(LinkS)`
  color: #000d1a;
  ${NavLinksCss}
  transition: 0.3s ease-in-out;

  &:hover {
    border-bottom: 3px solid #cd853f;
  }
`;

export const BarKLink = styled.a`
  color: #000d1a;
  ${NavLinksCss}
  transition: 0.3s ease-in-out;

  &:hover {
    border-bottom: 3px solid #cd853f;
  }
`;

export const PromoLink = styled(LinkR)`
  color: #000d1a;
  ${NavLinksCss}
  transition: 0.3s ease-in-out;

  &:hover {
    border-bottom: 3px solid #cd853f;
  }
`;

export const MenuTitle = styled.span`
  color: ${({ ScrollNav }) => (ScrollNav ? '#fff' : '#000d1a')};
`;

export const NavBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const BookLink = styled(LinkS)`
  background: ${({ primary }) => (primary ? '#CD853F' : '#000D1A')};
  white-space: nowrap;
  outline: none;
  border: none;
  min-width: 100px;
  max-width: 200px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ big }) => (big ? '16px 40px' : '14px 24px')};
  color: ${({ primary }) => (primary ? '#fff' : '#000D1A')};
  font-size: ${({ big }) => (big ? '20px' : '14px')};
  border-radius: ${({ round }) => (round ? '25px' : '5px')};
  margin-left: 40px;

  &:hover {
    transform: translateY(-2px);
    background: ${({ primary }) => (primary ? '#CD853F' : '#000D1A')};
    color: ${({ primary }) => (primary ? '#000' : '#fff')};
  }
`;
