import React, { useState } from 'react';
import Axios from 'axios';
import './App.scss';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { GlobalStyle } from './globalStyles';
import apiURL from './apiUrl';

import DropDown from './components/DropDown';
import Navbar from './components/Navbar';
import Home from './Pages';
import Promo from './Pages/promo';
import Footer from './components/Footer';
import Modal from './components/Modal';

const App = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [checkInDate, setCheckInDate] = useState(new Date());
  const [checkOutDate, setCheckOutDate] = useState(new Date());
  const [roomQty, setRoomQty] = useState(1);

  Axios.defaults.baseURL = apiURL.baseURL;
  // console.log(apiURL.baseURL);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const ToggleModal = () => {
    setShowModal((prev) => !prev);
  };

  return (
    <>
      <GlobalStyle />
      <Router>
        <Modal
          showModal={showModal}
          setShowModal={setShowModal}
          checkInDate={checkInDate}
          checkOutDate={checkOutDate}
          setCheckInDate={setCheckInDate}
          setCheckOutDate={setCheckOutDate}
          roomQty={roomQty}
          setRoomQty={setRoomQty}
        />
        <Navbar toggle={toggle} setShowModal={setShowModal} />
        <DropDown isOpen={isOpen} toggle={toggle} />
        <Switch>
          <Route exact path='/'>
            <Home
              ToggleModal={ToggleModal}
              checkInDate={checkInDate}
              checkOutDate={checkOutDate}
              setCheckInDate={setCheckInDate}
              setCheckOutDate={setCheckOutDate}
              roomQty={roomQty}
              setRoomQty={setRoomQty}
            />
          </Route>
          <Route exact path='/promo'>
            <Promo />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </>
  );
};

export default App;
