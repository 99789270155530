import React from 'react';
import Promo from '../components/Promo';

const PromoPage = () => {
  // const PdfFile = `${process.env.REACT_APP_APIBASEURL}/pdf/PromoTWL.pdf`;
  return (
    <>
      <Promo PdfFile={process.env.REACT_APP_BASEURL + '/pdf/PromoTWL.pdf'} />
    </>
  );
};

export default PromoPage;
