import React, { useState } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { MapContainer } from './MapElement';

const containerStyle = {
  width: '100%',
  height: '100%',
};

const center = {
  lat: 14.97208,
  lng: 145.63227,
};

const position = {
  lat: 14.97208,
  lng: 145.63227,
};

const MyGoogleMap = () => {
  const onLoad = (marker) => {
    // console.log('marker: ', marker);
  };

  return (
    <MapContainer>
      <LoadScript googleMapsApiKey={process.env.REACT_APP_APIMAP}>
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={17}>
          {/* Child components, such as markers, info windows, etc. */}
          <Marker onLoad={onLoad} position={position} />
        </GoogleMap>
      </LoadScript>
    </MapContainer>
  );
};

export default MyGoogleMap;
