import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import validator from 'validator';
import momentTZ from 'moment-timezone';

import {
  BackGround,
  ModalWrapper,
  ModalContent,
  CloseModalButton,
  ButtonWrapper,
  Button,
  FormContainer,
  Star,
  FormTitle,
  FormItem,
  FormLabel,
  FormInput,
  FormError,
  RadioLabel,
  RadioInput,
  RadioContainer,
  TextQuote,
  ButtonQuoteContainer,
} from './ModalElements';

const Modal = ({
  showModal,
  setShowModal,
  checkInDate,
  checkOutDate,
  setCheckInDate,
  setCheckOutDate,
  roomQty,
  setRoomQty,
}) => {
  const [nameError, setNameError] = useState('Msg');
  const [hasError, setHasError] = useState(false);
  const [emailError, setEmailError] = useState('Msg');
  const [hasEmailError, setHasEmailError] = useState(false);
  const [contactError, setContactError] = useState('Msg');
  const [hasContactError, setHasContactError] = useState(false);
  const [optRoomOnly, setOptRoomOnly] = useState(true);
  const [optRoomCar, setOptRoomCar] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [guestName, setGuestName] = useState('');
  const [guestEmail, setGuestEmail] = useState('');
  const [guestContact, setGuestContact] = useState('');
  const [isFormError, setIsFormError] = useState(false);

  useEffect(() => {
    if (showModal) {
      console.log(modifiedDate(checkInDate));
      console.log(modifiedDate(checkOutDate));

      setNameError('Msg');
      setHasError(false);
      setEmailError('Msg');
      setHasEmailError(false);
      setContactError('Msg');
      setHasContactError(false);
      setGuestName('');
      setGuestEmail('');
      setGuestContact('');
      setIsFormError(false);
      setIsProcessing(false);
      setRoomQty(roomQty);
      setCheckInDate(modifiedDate(checkInDate));
      setCheckOutDate(modifiedDate(checkOutDate));
      setOptRoomOnly(true);
      setOptRoomCar(false);
    }

    return () => {
      // cleanup
    };
  }, [showModal]);

  const SetInitState = () => {
    setNameError('Msg');
    setHasError(false);
    setEmailError('Msg');
    setHasEmailError(false);
    setContactError('Msg');
    setHasContactError(false);
    setGuestName('');
    setGuestEmail('');
    setGuestContact('');
    setIsFormError(false);
    setIsProcessing(false);
    setRoomQty(1);
    setCheckInDate(modifiedDate(new Date()));
    setCheckOutDate(modifiedDate(new Date()));
    setOptRoomOnly(true);
    setOptRoomCar(false);
  };

  const selectRoomOnly = () => {
    setOptRoomOnly((prev) => !prev);
    setOptRoomCar((prev) => !prev);
  };

  const selectRoomCar = () => {
    setOptRoomOnly((prev) => !prev);
    setOptRoomCar((prev) => !prev);
  };

  const OnChangeRoomQty = (e) => {
    setRoomQty(e.target.value);
  };

  const modifiedDate = (dateValue) => {
    let AdjDate = new Date(momentTZ.tz(dateValue, 'Pacific/Guam'));
    return AdjDate;
  };

  const onChangeCheckInDate = (e) => {
    // console.log(e.target.value);
    let dateValue = modifiedDate(e.target.value);
    setCheckInDate(dateValue);
    let tomorrow = modifiedDate(dateValue);
    tomorrow.setDate(dateValue.getDate() + 1);
    setCheckOutDate(tomorrow);
  };
  const onChangeCheckOutDate = (e) => {
    // console.log(e.target.value);
    setCheckOutDate(modifiedDate(e.target.value));
  };

  const formatDate = (valueDate) => {
    // console.log(valueDate);
    // return moment(valueDate).format('yyyy-mm-dd');
    let d = modifiedDate(valueDate),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  const handleEmail = async (ev) => {
    ev.preventDefault();
    try {
      let validate = await validation();

      if (!validate) {
        // console.log({ validate });
        return false;
      } else {
        if (!hasError && !hasEmailError && !hasContactError) {
          setIsProcessing(true);
          sendEmailReservation()
            .then((result) => {
              console.log(result);
              alert(
                'Your reservation request has been sent to our front desk and will contact you.'
              );
              setIsProcessing(false);
              SetInitState();
              setShowModal((prev) => !prev);
            })
            .catch((err) => {
              console.log(err);
              alert(
                'We apologize but the service is not available. Please contact us via email or call'
              );
              setCheckInDate(new Date());
              setCheckOutDate(new Date());
              setIsProcessing(false);
              setShowModal((prev) => !prev);
            });
        }
      }
    } catch (e) {
      console.log(e);
      setIsProcessing(false);
    }
  };

  const sendEmailReservation = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let bodyData = {
          CheckIn: checkInDate,
          CheckOut: checkOutDate,
          Rooms: roomQty,
          Type: optRoomOnly === true ? 'RoomOnly' : 'RoomCar',
          GuestName: guestName,
          Gemail: guestEmail,
          Contact: guestContact,
        };

        let response = await Axios.post(`/api/email/requestbooking`, bodyData);

        if (response.data === 'Success') {
          resolve('Success');
        } else {
          reject('Failed');
        }
        // console.log({ bodyData });
      } catch (e) {
        console.log(e);
        reject('Failed');
      }
    });
  };

  const handleChangeName = (ev) => {
    setGuestName(ev.target.value);
    setHasError(false);
  };

  const handleChangeEmail = (ev) => {
    setGuestEmail(ev.target.value);
    setHasEmailError(false);
  };

  const handleChangeContact = (ev) => {
    setGuestContact(ev.target.value);
    setHasContactError(false);
  };

  const validation = () => {
    return new Promise((resolve, reject) => {
      // setIsFormError(false);

      if (guestName.length === 0) {
        setNameError('Please enter your name!');
        setHasError(true);
        setIsFormError(true);
        resolve(false);
      }

      if (!validator.isEmail(guestEmail)) {
        setEmailError('Please enter valid email address!');
        setHasEmailError(true);
        setIsFormError(true);
        resolve(false);
      }

      if (validator.isEmpty(guestContact)) {
        setContactError('Please enter contact number!');
        setHasContactError(true);
        setIsFormError(true);
        resolve(false);
      }

      resolve(true);
    });
  };

  const handleCloseModal = () => {
    if (!isProcessing) {
      SetInitState();
      setShowModal((prev) => !prev);
    }
  };
  return (
    <>
      {showModal ? (
        <BackGround>
          <ModalWrapper>
            <ModalContent>
              <FormContainer action='/' method='POST'>
                <FormTitle>
                  Request Reservation
                  <Star>*</Star>
                </FormTitle>
                <FormItem>
                  <FormLabel htmlFor='givenname'>Full Name</FormLabel>
                  <div>
                    <FormInput
                      type='text'
                      name='givenname'
                      id='givenname'
                      placeholder='Enter your given name'
                      hasError={hasError}
                      onChange={handleChangeName}
                    />
                    <FormError hasError={hasError}>{nameError}</FormError>
                  </div>
                </FormItem>
                <FormItem>
                  <FormLabel htmlFor='email'>Email</FormLabel>
                  <div>
                    <FormInput
                      type='email'
                      name='email'
                      id='email'
                      placeholder='Enter your email address'
                      hasError={hasEmailError}
                      onChange={handleChangeEmail}
                    />
                    <FormError hasError={hasEmailError}>{emailError}</FormError>
                  </div>
                </FormItem>
                <FormItem>
                  <FormLabel htmlFor='contact'>Contact No.</FormLabel>
                  <div>
                    <FormInput
                      type='text'
                      name='contact'
                      id='contact'
                      placeholder='Enter your contact number'
                      hasError={hasContactError}
                      onChange={handleChangeContact}
                    />
                    <FormError hasError={hasContactError}>
                      {contactError}
                    </FormError>
                  </div>
                </FormItem>
                <FormItem>
                  <FormLabel htmlFor='rooms'>Rooms</FormLabel>
                  <div>
                    <FormInput
                      type='number'
                      name='rooms'
                      id='rooms'
                      placeholder='How many rooms? (Max 8)'
                      hasError={false}
                      max='8'
                      min='1'
                      onChange={OnChangeRoomQty}
                      value={roomQty}
                    />
                    <FormError hasError={false}>''</FormError>
                  </div>
                </FormItem>
                <FormItem>
                  <FormLabel htmlFor='checkin'>Check In</FormLabel>
                  <div>
                    <FormInput
                      type='date'
                      name='checkin'
                      id='checkin'
                      hasError={false}
                      min={formatDate(new Date())}
                      value={formatDate(checkInDate)}
                      onChange={onChangeCheckInDate}
                    />
                    <FormError hasError={false}>
                      A sample Error Message
                    </FormError>
                  </div>
                </FormItem>
                <FormItem>
                  <FormLabel htmlFor='checkout'>Check Out</FormLabel>
                  <div>
                    <FormInput
                      type='date'
                      name='checkout'
                      id='checkout'
                      hasError={false}
                      min={formatDate(checkInDate)}
                      value={formatDate(checkOutDate)}
                      onChange={onChangeCheckOutDate}
                    />
                    <FormError hasError={false}>
                      A sample Error Message
                    </FormError>
                  </div>
                </FormItem>
                <RadioLabel htmlFor='roomOnly'>
                  <RadioInput
                    type='radio'
                    name='myRadioField'
                    id='roomOnly'
                    onClick={selectRoomOnly}
                  />
                  <RadioContainer optRoomOnly={optRoomOnly}></RadioContainer>
                  Room Only $140.00 / night plus tax
                </RadioLabel>
                <RadioLabel htmlFor='roomHertz'>
                  <RadioInput
                    type='radio'
                    name='myRadioField'
                    id='roomHertz'
                    onClick={selectRoomCar}
                  />
                  <RadioContainer optRoomOnly={optRoomCar}></RadioContainer>
                  Room and Car Package $176.00 / day plus tax
                </RadioLabel>
              </FormContainer>
              <ButtonQuoteContainer>
                <TextQuote>
                  Please note: All reservation requests are not confirmed until
                  official confirmation notification is provided by Tinian
                  Western Lodge.
                </TextQuote>
                <ButtonWrapper>
                  <Button onClick={handleEmail} disabled={isProcessing}>
                    {isProcessing ? 'Processing...' : 'Send Booking Request'}
                  </Button>
                </ButtonWrapper>
              </ButtonQuoteContainer>
            </ModalContent>
            <CloseModalButton
              aria-label='Close Modal'
              onClick={handleCloseModal}
            />
          </ModalWrapper>
        </BackGround>
      ) : null}
    </>
  );
};

export default Modal;
