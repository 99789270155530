import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import {
  AmenitiesWrapper,
  BgContainer,
  Container,
  ContentContainer,
  Icon,
  RoomTitle,
  RatePrice,
  RateContainer,
} from './RoomElements';

import BookDates from '../BookDates';

import { AmenitiesData } from '../../data/AmenitiesData';

gsap.registerPlugin(ScrollTrigger);

const Room = ({
  ToggleModal,
  checkInDate,
  checkOutDate,
  setCheckInDate,
  setCheckOutDate,
  roomQty,
  setRoomQty,
}) => {
  let roomRef = useRef();

  useEffect(() => {
    gsap.from('.animate-room', {
      duration: 0.5,
      autoAlpha: 0,
      y: -150,
      scrollTrigger: {
        trigger: '#Room',
        endTrigger: '#Contact',
        start: 'top center',
        end: 'bottom +=50%',
        toggleActions: 'play none none reverse',
        // markers: true,
      },
    });
  }, []);

  return (
    <Container id='Room'>
      <AmenitiesWrapper
        ref={(element) => {
          roomRef = element;
        }}
      >
        <BgContainer className='animate-room'>
          <ContentContainer>
            <h1>Our Amenities</h1>
            <p>
              Tinian Western Lodge is an affordable option for those seeking
              relaxation, adventure or need for a clean, safe and comfortable
              place to stay for business or pleasure.
            </p>
            <ul>
              {AmenitiesData.map((item, index) => (
                <li key={index}>
                  <Icon />
                  {item.label}
                </li>
              ))}
            </ul>
            <RoomTitle>Room Only</RoomTitle>
            <RateContainer>
              <RatePrice>$140.00</RatePrice>
              <p>/ night plus tax</p>
            </RateContainer>
            <RoomTitle>Room and Car Package</RoomTitle>
            <RateContainer>
              <RatePrice>$176.00</RatePrice>
              <p>/ night plus tax</p>
            </RateContainer>
          </ContentContainer>
        </BgContainer>
        <BookDates
          ToggleModal={ToggleModal}
          checkInDate={checkInDate}
          checkOutDate={checkOutDate}
          setCheckInDate={setCheckInDate}
          setCheckOutDate={setCheckOutDate}
          roomQty={roomQty}
          setRoomQty={setRoomQty}
        />
      </AmenitiesWrapper>
    </Container>
  );
};

export default Room;
