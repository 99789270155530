import styled from 'styled-components';
import ImageRoom from '../../images/Image6.jpg';
import ImgBg from '../../images/bg_amenities.png';
import { FaCheck } from 'react-icons/fa';

export const Container = styled.div`
  background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1)),
    url(${ImageRoom});
  height: 60%;
  background-position: center;
  background-size: cover;

  @media screen and (max-width: 768px) {
    background: none;
    height: 50%;
  }
`;

export const AmenitiesWrapper = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  transform: translateY(-5%);
  left: 0;
  flex-direction: column;
  /* background-color: yellow; */
`;

export const BgContainer = styled.div`
  background: url(${ImgBg});
  background-position: center;
  background-size: cover;
  object-fit: fill;
  background-color: #284a9d;
  margin-left: 50px;
  width: calc(100vw - 54%);
  @media screen and (max-width: 768px) {
    margin: 0;
    width: 100vw;
  }
`;

export const ContentContainer = styled.div`
  z-index: 50;
  top: 0;
  margin-top: 2rem;
  max-width: 700px;
  padding: 0 2rem;
  height: 600px;
  justify-content: center;
  align-items: center;
  color: #fff;

  @media screen and (max-width: 768px) {
    height: 100%;
    padding: 0 1rem;
    margin-top: 1rem;
  }

  h1 {
    font-weight: 400;
    margin-bottom: 0.5rem;
    font-size: clamp(0.5rem, 5vw, 2rem);
  }

  p {
    font-weight: 100;
    line-height: 2rem;
    font-size: clamp(0.5rem, 5vw, 1rem);
  }

  ul {
    list-style: none;
    padding-top: 1rem;
    width: 100%;
    columns: 1;
    font-size: clamp(0.5rem, 5vw, 0.8rem);

    @media screen and (max-width: 768px) {
      columns: 1;
      padding-top: 0.7rem;
      font-size: 0.6em;
    }
  }

  ul,
  li {
    display: block;
    padding-left: 0.5rem;
    margin-bottom: 0.5rem;

    @media screen and (max-width: 768px) {
      padding-left: 0;
    }
  }
`;

export const Icon = styled(FaCheck)`
  margin: 0 1rem;
  @media screen and (max-width: 768px) {
    margin-left: 0;
    margin-right: 0.5rem;
  }
`;

export const AmenitiesConList = styled.div``;

export const DateContainer = styled.div`
  display: flex;
  position: absolute;
  /* z-index: 15; */
  bottom: 0;
  transform: translateY(40%);
  width: 100%;
  height: 100px;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    position: relative;
    transform: 0;
    transform: translateY(0%);
  }
`;

export const DateWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  background-color: #faf9f9;
  max-width: 95vw;
  z-index: 50;
  border-radius: 10px;

  @media screen and (max-width: 768px) {
    background-color: #284a9d;
    color: #fff;
    border-radius: 0;
    max-width: 100vw;
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  h2 {
    margin-right: 2rem;
  }
`;

export const RoomTitle = styled.span`
  font-size: clamp(1rem, 5vw, 2rem);
  font-weight: 700;
`;
export const RateContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: baseline;
  align-items: baseline;
  margin: 0.5rem 0;
`;

export const RatePrice = styled.span`
  font-size: clamp(1rem, 5vw, 2rem);
  padding-right: 0.5rem;
`;
