import React, { useEffect, useRef } from 'react';
// import { ButtonCSS } from '../Button';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import {
  Section,
  SectionContainer,
  ColumnLeft,
  ColumnRight,
  Line,
  BookLink,
} from './InfoSectionElements';
gsap.registerPlugin(ScrollTrigger);

const InfoSection = ({
  heading,
  paragraphOne,
  buttonLabel,
  reverse,
  image,
  ScrollId,
  ToggleModal,
}) => {
  let infoRef = useRef();

  useEffect(() => {
    gsap.from('.animate-about', {
      duration: 0.5,
      autoAlpha: 0,
      ease: 'none',
      x: -200,
      scrollTrigger: {
        trigger: '#About',
        endTrigger: '#Room',
        start: 'top center',
        end: 'bottom +=50%',
        toggleActions: 'play none none reverse',
        // markers: true,
      },
    });

    gsap.from('.animate-img', {
      duration: 0.5,
      autoAlpha: 0,
      ease: 'none',
      x: 200,
      scrollTrigger: {
        trigger: '#About',
        endTrigger: '#Room',
        start: 'top center',
        end: 'bottom +=50%',
        toggleActions: 'play none none reverse',
        // markers: true,
      },
    });
  }, []);

  // const handleMailTo = () => {
  //   let email = 'reservation@tinianwesternlodge.com';
  //   let subject = 'Requesting for reservation';
  //   let emailBody = '';
  //   document.location = `mailto:${email}?subject=${subject}&body=${emailBody}`;
  // };

  return (
    <Section id={ScrollId}>
      <SectionContainer
        ref={(element) => {
          infoRef = element;
        }}
      >
        <ColumnLeft className='animate-about'>
          <h1>{heading}</h1>
          <Line />
          <p>{paragraphOne}</p>
          <BookLink
            to='Room'
            smooth={true}
            duration={500}
            spy={true}
            exact='true'
            offset={-50}
            primary='false'
            onClick={ToggleModal}
          >
            {buttonLabel}
          </BookLink>
        </ColumnLeft>
        <ColumnRight reverse={reverse}>
          <img src={image} alt='room' className='animate-img' />
        </ColumnRight>
      </SectionContainer>
    </Section>
  );
};

export default InfoSection;
